import Button from "@material-ui/core/Button";
const CustomForm = ({
    status,
    message,
    onValidated,
    closePopup,
    callSuscribeApi,
    formSubmitted,
    setFormSubmitted,
    data,
    isRTL,
}) => {
    let email, fname, lname;
    const submit = () => {
        onValidated({
            EMAIL: email.value,
            FNAME: fname.value,
            LNAME: lname.value,
            validationError: !(
                email &&
                fname.value &&
                lname.value &&
                email.value.indexOf("@") > -1
            ),
        });
    };
    return (
        <div
            style={{
                fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                backgroundImage: `url(/assets/images/popupbg.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                padding: "0px 32px 32px",
            }}
        >
            {status === "error" && (
                <div
                    style={{ color: "red" }}
                    // dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && !formSubmitted && (
                <div
                    style={{ color: "green" }}
                    // dangerouslySetInnerHTML={{ __html: message }}
                >
                    {
                        (callSuscribeApi({ email: data.EMAIL }),
                        closePopup(),
                        setFormSubmitted(true))
                    }
                </div>
            )}
            <div
                style={{
                    height: window.innerWidth < 600 ? "52px" : "100px",
                    width: window.innerWidth < 600 ? "180px" : "290px",
                    marginLeft: isRTL ? "0px" : "-6px",
                    marginRight: isRTL ? "-13px" : "0px",
                }}
            >
                {isRTL ? (
                    <img src="/assets/images/logolow.png" />
                ) : (
                    <img src="/assets/images/popupenlogo.png" />
                )}
            </div>
            <h2
                style={{
                    color: "#0aaad2",
                    marginTop: "0px !important",
                    marginBottom: "15px !important",
                    fontSize: window.innerWidth < 600 ? "24px" : "26px",
                    fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                }}
            >
                {isRTL
                    ? "اشترك في نشرتنا البريدية"
                    : "Subscribe to our newsletter"}
            </h2>
            <h2
                style={{
                    color: "#272d2f",
                    fontSize: "18px",
                    fontWeight: "100",
                    padding: 0,
                    margin: "8px 0px 12px",
                    fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                }}
            >
                {isRTL
                    ? "سجل هنا للاطلاع على أحدث تحليلات وأخبار مركز الإمارات للسياسات "
                    : "Register here to read EPC's latest analyses and news "}
            </h2>
            <div
                style={{
                    fontWeight: 700,
                    textAlign: isRTL ? "left" : "right",
                    direction: isRTL ? "left" : "right",
                    fontSize: 11,
                    marginRight: "4%",
                }}
            ></div>
            <div
                style={{
                    clear: "left",
                    position: "relative",
                    width: "96%",
                    paddingBottom: "6px",
                    minHeight: 50,
                    fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                }}
                contenteditable="true"
            >
                <label
                    style={{
                        color: "#000",
                        display: "block",
                        marginBottom: 3,
                        fontSize: isRTL ? "20px" : "16px",
                    }}
                >
                    {isRTL ? "البريد الإلكتروني" : "Email Address"}
                    <span class="asterisk" style={{ color: "red" }}>
                        *
                    </span>
                </label>
                <input
                    style={{
                        border: "1px solid #abb0b2",
                        borderRadius: 9,
                        display: "block",
                        width: "100%",
                        padding: "8px 0",
                        textIndent: "2%",
                        outline: "none",
                        height: window.innerWidth < 600 ? "38px" : "48px",
                        color: "rgb(117 117 117)",
                        margin: window.innerWidth < 600 ? "0px" : "8px 0px",
                        fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                    }}
                    ref={(node) => (email = node)}
                    type="text"
                    required
                />
            </div>
            <div
                style={{
                    clear: "left",
                    position: "relative",
                    width: "96%",
                    paddingBottom: "6px",
                    minHeight: 50,
                    fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                }}
                contenteditable="true"
            >
                <label
                    style={{
                        color: "#000",
                        display: "block",
                        marginBottom: 3,
                        fontSize: isRTL ? "20px" : "16px",
                    }}
                >
                    {isRTL ? "الاسم الأول" : "First Name"}

                    <span class="asterisk" style={{ color: "red" }}>
                        *
                    </span>
                </label>
                <input
                    style={{
                        outline: "none",
                        color: "rgb(117 117 117)",
                        border: "1px solid #abb0b2",
                        borderRadius: 9,
                        display: "block",
                        width: "100%",
                        padding: "8px 0",
                        textIndent: "2%",
                        height: window.innerWidth < 600 ? "38px" : "48px",
                        margin: window.innerWidth < 600 ? "0px" : "8px 0px",
                        fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                    }}
                    ref={(node) => (fname = node)}
                    type="text"
                    required
                />
            </div>
            <div
                style={{
                    clear: "left",
                    position: "relative",
                    width: "96%",
                    paddingBottom: "6px",
                    minHeight: 50,
                    fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                }}
                contenteditable="true"
            >
                <label
                    style={{
                        color: "#000",
                        display: "block",
                        marginBottom: 3,
                        fontSize: isRTL ? "20px" : "16px",
                    }}
                >
                    {isRTL ? "الاسم الأخير" : "Last Name"}
                    <span class="asterisk" style={{ color: "red" }}>
                        *
                    </span>
                </label>
                <input
                    style={{
                        border: "1px solid #abb0b2",
                        borderRadius: 9,
                        display: "block",
                        width: "100%",
                        padding: "8px 0",
                        textIndent: "2%",
                        outline: "none",
                        height: window.innerWidth < 600 ? "38px" : "48px",
                        color: "rgb(117 117 117)",
                        margin: window.innerWidth < 600 ? "0px" : "8px 0px",
                        fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                    }}
                    ref={(node) => (lname = node)}
                    type="text"
                    required
                />
            </div>
            <div
                style={{
                    display: window.innerWidth < 600 ? "block" : "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    paddingLeft: isRTL ? "18px" : "0px",
                    paddingRight: isRTL ? "0px" : "18px",
                }}
            >
                <div
                    style={{
                        fontWeight: 800,
                        fontSize: 16,
                    }}
                >
                    <span style={{ color: "red" }}>*</span>
                    <span>
                        {isRTL
                            ? "ملء هذه الخانات إلزامي."
                            : "Filling this box is compulsory."}
                    </span>
                </div>
                <Button
                    style={{
                        backgroundColor: "#0aaad2",
                        color: "#fff",
                        clear: "both",
                        border: "0 none",
                        borderRadius: 8,
                        fontWeight: "normal",
                        height: 40,
                        margin:
                            window.innerWidth < 600
                                ? "15px 0px 10px 0px"
                                : "32px 5px 10px 0px",
                        padding: "0 12px",
                        textAlign: "center",
                        textDecoration: "none",
                        verticalAlign: "top",
                        whiteSpace: "nowrap",
                        width: "fit-content",
                        fontSize: window.innerWidth < 600 ? "16px" : "18px",
                        fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                    }}
                    onClick={submit}
                >
                    {isRTL ? "اشترك" : "Subscribe"}
                </Button>
            </div>
            <div
                style={{
                    fontSize: window.innerWidth < 600 ? "16px" : "18px",
                    fontWeight: 400,
                    marginTop: window.innerWidth < 600 ? "0px" : "30px",
                    display: window.innerWidth < 600 ? "block" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                }}
            >
                <span
                    style={{
                        fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                    }}
                >
                    {" "}
                    {isRTL
                        ? "عند الاشتراك، أنت توافق على "
                        : "When you subscribe, you agree to our "}
                </span>

                {isRTL ? (
                    <a
                        href="https://epc.ae/ar/privacyPolicy"
                        target="_blank"
                        style={{
                            textDecoration: "underline!important",
                        }}
                    >
                        &nbsp; سياسة الخصوصية &nbsp;
                    </a>
                ) : null}
                {isRTL ? <span>الخاصة بنا.</span> : null}
                {!isRTL ? (
                    <a
                        target="_blank"
                        href="https://epc.ae/en/privacyPolicy"
                        style={{
                            textDecoration: "underline!important",
                            fontFamily: isRTL ? "Sakkal Majalla" : "Garamond",
                        }}
                    >
                        {" "}
                        &nbsp; privacy policy.
                    </a>
                ) : null}
            </div>
        </div>
    );
};
export default CustomForm;
