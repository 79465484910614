import { Box, Button, Dialog, DialogContent } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { setDirection } from "../redux/actionCreators/theme";
import Spinner from "../Spinner";
import { buildCanonicalUrl } from "../utils/url";
import CustomForm from "../components/footer/SubscribeForm";
import Routes from "./Routes";
import actions from "../redux/actions";
import MailchimpSubscribe from "./mailChimp/src";
import { stat } from "fs";
const { subscribeAction, subscribeReturned } = actions;

// async function buildCanonicalUrl(url, currentLanguage) {
//   await checkLegacyUrl(url);
//   alert("HALT");
//   const urlParts = url.split("/").filter(Boolean);

//   if (urlParts[0] !== "en" && urlParts[0] !== "ar") {
//     urlParts.unshift(currentLanguage);
//   }
//   const urlLanguage = urlParts[0];

//   if (urlParts.length === 1) {
//     urlParts.push("home");
//   }

//   return { canonicalUrl: "/" + urlParts.join("/"), urlLanguage };
// }

function Index() {
    const isRTL = useSelector((state) => state.theme_reducer.basicTheme.isRTL);
    const [processed, setProcessed] = useState(false);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [openSubscribeForm, setOpenSubscribeForm] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    let currentLanguage = isRTL ? "ar" : "en";
    useEffect(() => {
        let pop_status_AR = sessionStorage.getItem("SUBSCRIBE_FORM_AR");
        let pop_status_EN = sessionStorage.getItem("SUBSCRIBE_FORM_EN");
        setTimeout(() => {
            if (pop_status_AR != "true" && currentLanguage == "ar") {
                setOpenSubscribeForm(true);
            }
            if (pop_status_EN != "true" && currentLanguage == "en") {
                setOpenSubscribeForm(true);
            }
        }, 5000);
    }, [isRTL]);

    const { pathname: currentUrl } = useLocation();
    const callSuscribeApi = ({ email }) => {
        dispatch(
            subscribeAction({
                data: { language: isRTL ? "ar" : "en", email },
            })
        );
    };

    useEffect(() => {
        (async () => {
            const urlParts = currentUrl.split("/").filter(Boolean);
            const language = urlParts[0];
            if (
                (language === "ar" || language === "en") &&
                currentLanguage !== language
            ) {
                dispatch(setDirection({ isRTL: language === "ar" }));
                i18n.changeLanguage(language === "en" ? "en" : "ar");
                currentLanguage = language;
            }
            setProcessed(false);
            const storedCanonicalUrl = sessionStorage.getItem(
                "stored-canonical-url"
            );

            let canonicalUrl, canonicalLanguage;

            if (storedCanonicalUrl === currentUrl) {
                canonicalUrl = currentUrl;
                canonicalLanguage = currentLanguage;
            }
            else {
                const canonical = await buildCanonicalUrl(
                    currentUrl,
                    currentLanguage
                );
                canonicalUrl = canonical?.canonicalUrl;
                canonicalLanguage = canonical?.canonicalLanguage;
                sessionStorage.setItem("stored-canonical-url", canonicalUrl);
            }
            if (currentLanguage !== canonicalLanguage) {
                dispatch(setDirection({ isRTL: !isRTL }));
                i18n.changeLanguage(isRTL ? "en" : "ar");
            }
            if (canonicalUrl === currentUrl) {
                setProcessed(true);
                return;
            }
            window.location.replace(canonicalUrl);
        })();
    }, [currentUrl]);

    if (!processed) {
        return <Spinner />;
    }

    const arUrl =
        "https://epc.us4.list-manage.com/subscribe/post?u=2c7527a65f8ab456bbbbcc453&amp;id=19be356b68";
    const enUrl =
        "https://epc.us4.list-manage.com/subscribe/post?u=2c7527a65f8ab456bbbbcc453&amp;id=cde8e3de12";

    const closePopup = () => {
        setOpenSubscribeForm(false);
    };

    return (
        <>
            <Dialog
                maxWidth="md"
                id="subscribePopUp"
                open={openSubscribeForm}
                style={{ direction: isRTL ? "rtl" : "ltr" }}
            // aria-labelledby="form-dialog-title"
            //  className={classes.replyDialog}
            >
                <Box
                    style={{
                        padding: "8px 19px 0px",
                    }}
                >
                    <button
                        style={{
                            minWidth: "0px",
                            padding: "0px",
                            height: 25,
                            width: 25,
                            backgroundColor: "#fff",
                            border: "1px solid gray",
                            borderRadius: "14px",
                            justifyContent: "flex-end",
                            alignSelf: "start",
                            cursor: "pointer",
                            position: "relative",
                            left: isRTL ? "unset" : "calc(100% - 15px)",
                            right: isRTL ? "calc(100% - 15px)" : "unset",
                        }}
                        onClick={() => {
                            setOpenSubscribeForm(false);
                        }}
                    >
                        <span
                            style={{
                                width: "25px",
                                height: "25px",
                                color: "#575454",
                                marginTop: "32px",
                            }}
                        >
                            {" "}
                            ✖
                        </span>
                    </button>
                </Box>
                <DialogContent
                    style={{ paddingTop: "0px" }}
                    id="subscribeContent"
                >
                    <MailchimpSubscribe
                        url={isRTL ? arUrl : enUrl}
                        render={({ subscribe, status, message, data }) => (
                            <CustomForm
                                setFormSubmitted={setFormSubmitted}
                                formSubmitted={formSubmitted}
                                status={status}
                                message={message}
                                onValidated={(formData) => subscribe(formData)}
                                closePopup={closePopup}
                                callSuscribeApi={callSuscribeApi}
                                data={data}
                                isRTL={isRTL}
                            />
                        )}
                    />
                </DialogContent>
            </Dialog>
            <Switch>
                <Route path="/:language/" component={Routes} />
            </Switch>
        </>
    );
}

export default Index;
