import { createAction } from "../creators";

export default {
    ...createAction("DO_SEARCH", "data", "language"),
    ...createAction("DO_SEARCH_RETURNED", "data"),

    ...createAction("SEARCH_BY_TYPE", "data", "language"),
    ...createAction("SEARCH_BY_TYPE_RETURNED", "data"),

    ...createAction("DO_SEARCH_ACADEMY", "data", "language"),
    ...createAction("DO_SEARCH_ACADEMY_RETURNED", "data"),
};
