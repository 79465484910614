import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    none: {
        display: "none",
    },

    root: {
        position: "fixed",
        display: "block",
        bottom: 20,
        right: 30,
        zIndex: 99,
        border: 0,
        outline: 0,
        backgroundColor: "rgba(0,0,0,0.7)",
        cursor: "pointer",
        padding: "5px 8px",
        borderRadius: 4,
        transition: ".5s",
        animation: "$appear 0.7s 1",
        animationFillMode: "forwards",

        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
        },

        "& svg": {
            fontSize: "33px",
            color: " #00aedb",
        },
    },

    "@keyframes appear": {
        "0%": {
            opacity: 0,
            bottom: "-50px",
        },

        "100%": {
            opacity: 1,
            bottom: 20,
        },
    },
}));

export default useStyles;
