import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function TabTitle() {
    const {
        basicTheme: { isRTL },
    } = useSelector((state) => state.theme_reducer);

    const { pageTitle } = useSelector((state) => state.tabTitle);

    const title = isRTL
        ? ` مركز الإمارات للسياسات ${pageTitle ? "|" : ""} ${pageTitle}`
        : `Emirates Policy Center ${pageTitle ? "|" : ""} ${pageTitle}`;

    return <Helmet title={title}></Helmet>;
}

export default memo(TabTitle);
