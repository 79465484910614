import { put, call, takeEvery, takeLatest, all } from "redux-saga/effects";
import {
    uploadFile,
    createFile,
    deleteFile,
    downloadFile,
} from "../../network/file";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import actions from "../../actions";
const MySwal = withReactContent(Swal);
const {
    UPLOAD_FILE_ACTION,
    fileCreated,
    DELETE_FILE_ACTION,
    fileDeleted,
    DOWNLOAD_FILE,
    fileDownloaded,
} = actions;

function* performUploadFile(action) {
    try {
        var formData = new FormData();
        const data = action.files;

        data.map((file) => formData.append("files", file));

        const result = yield call(uploadFile, formData);

        if (result.data.success) {
            const filesDetails = [];

            result.data.files.map((file) =>
                filesDetails.push({
                    uuid: file.filename,
                    publishMode: 1,
                    acl: 1,
                    extension: file.originalname.split(".").pop(),
                    ...file,
                })
            );

            const createdFile = yield call(createFile, filesDetails);

            const response = createdFile.data.files;

            if (createdFile) yield put(fileCreated({ response }));
            else yield put(fileCreated({ response: [] }));
        } else {
            yield put(fileCreated({ response: [] }));
            MySwal.fire({
                icon: "error",
                title: `"${action.files[0].name}"  ${result.data.message}`,
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export function* watchUploadFile() {
    yield takeEvery(UPLOAD_FILE_ACTION, performUploadFile);
}

function* performDeleteFileById(action) {
    try {
        const result = yield call(deleteFile, action.id);

        if (result) yield put(fileDeleted({ response: result.data }));
        else yield put(fileDeleted({ response: [] }));
    } catch (error) {
        console.error(error);
    }
}
export function* watchDeleteFileById() {
    yield takeLatest(DELETE_FILE_ACTION, performDeleteFileById);
}
function* performDownloadFile(action) {
    try {
        if (action.payload.files) {
            let files = action.payload.files;

            let fullDataFiles = yield all(
                files &&
                    files.map(async (file) => ({
                        ...file,
                        binaryData: await downloadFile(file.uuid),
                    }))
            );

            yield put(fileDownloaded({ response: fullDataFiles }));
        } else if (action.payload.item) {
            let data = action.payload.item.uuid;
            const result = yield call(downloadFile, data);
            yield put(fileDownloaded({ response: result }));
        }
    } catch (error) {
        yield put(fileDownloaded({ response: [] }));
    }
}
export function* watchDownloadFile() {
    yield takeLatest(DOWNLOAD_FILE, performDownloadFile);
}
