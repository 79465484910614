import { put, call, takeEvery } from "redux-saga/effects";
import { getUnits } from "../../network/units";
import actions from "../../actions";

const { LIST_ALL_UNITS, allUnitsReturned } = actions;

function* performGetUnits(action) {
    try {
        const { language } = action;
        const result = yield call(getUnits, language);
        if (result) yield put(allUnitsReturned({ data: result.res }));
        else yield put(allUnitsReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetUnits() {
    yield takeEvery(LIST_ALL_UNITS, performGetUnits);
}
