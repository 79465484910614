import { put, takeLatest, call } from "redux-saga/effects";
import {
    submitServiceForm,
    fetchMyRequests,
    fetchRequestDetails,
    addRequestNote,
    fetchRequestStatusChanges,
    fetchRequestNotes,
    submitSupplierForm,
} from "../../network/services";
import actions from "../../actions";

const {
    POST_SERVICE_FORM,
    postServiceFormReturned,
    GET_MY_REQUESTS,
    getMyRequestsDone,
    GET_REQUEST_DETAILS,
    getRequestDetailsDone,
    ADD_REQUEST_NOTE,
    addRequestNoteDone,
    GET_REQUEST_NOTES,
    getRequestNotesDone,
    GET_REQUEST_STATUS_CHANGES,
    getRequestStatusChangesDone,
    POST_SUPPLIER_FORM,
    postSupplierFormReturned,
} = actions;

function* performSubmitServiceForm(action) {
    try {
        const data = action.data;

        const result = yield call(submitServiceForm, data);

        if (result) yield put(postServiceFormReturned({ data: result.data }));
        else yield put(postServiceFormReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchSubmitServiceForm() {
    yield takeLatest(POST_SERVICE_FORM, performSubmitServiceForm);
}

function* performGetMyRequests() {
    try {
        const result = yield call(fetchMyRequests);
        if (
            result.networkSuccess &&
            result.res.serviceRequests &&
            result.res.serviceRequests[0]
        )
            yield put(getMyRequestsDone({ data: result.res.serviceRequests }));
        else yield put(getMyRequestsDone({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetMyRequests() {
    yield takeLatest(GET_MY_REQUESTS, performGetMyRequests);
}

function* performFetchRequestDetails({ id }) {
    try {
        const result = yield call(fetchRequestDetails, id);
        if (result.networkSuccess && result.res && result.res.success) {
            yield put(
                getRequestDetailsDone({
                    data: result.res,
                })
            );
        } else {
            yield put(getRequestDetailsDone({ data: [] }));
        }
    } catch (error) {
        console.error(error);
    }
}
export function* watchFetchRequestsDetails() {
    yield takeLatest(GET_REQUEST_DETAILS, performFetchRequestDetails);
}

function* performAddRequestNote({ data }) {
    try {
        const result = yield call(addRequestNote, data);
        if (result.networkSuccess) {
            yield put(addRequestNoteDone({ data: result.data }));
        } else yield put(addRequestNoteDone({ data: result.data }));
    } catch (e) {
        yield put(addRequestNoteDone({ data: { success: false } }));
        return;
    }
}
export function* watchAddRequestNote() {
    yield takeLatest(ADD_REQUEST_NOTE, performAddRequestNote);
}

function* performFetchRequestNotes({ id }) {
    try {
        const result = yield call(fetchRequestNotes, id);

        if (result.networkSuccess && result.res.notes && result.res.notes[0])
            yield put(getRequestNotesDone({ data: result.res.notes }));
        else yield put(getRequestNotesDone({ data: [] }));
    } catch (e) {
        yield put(getRequestNotesDone({ data: { success: false } }));
        return;
    }
}
export function* watchFetchRequestNotes() {
    yield takeLatest(GET_REQUEST_NOTES, performFetchRequestNotes);
}

function* performFetchRequestStatusChanges({ id }) {
    try {
        const result = yield call(fetchRequestStatusChanges, id);
        if (
            result.networkSuccess &&
            result.res.changes &&
            result.res.changes[0]
        )
            yield put(
                getRequestStatusChangesDone({ data: result.res.changes })
            );
        else yield put(getRequestStatusChangesDone({ data: [] }));
    } catch (e) {
        yield put(getRequestStatusChangesDone({ data: { success: false } }));
        return;
    }
}
export function* watchFetchRequestStatusChanges() {
    yield takeLatest(
        GET_REQUEST_STATUS_CHANGES,
        performFetchRequestStatusChanges
    );
}

function* performSubmitSupplierForm(action) {
    try {
        const data = action.data;
        const result = yield call(submitSupplierForm, data);
        if (result) yield put(postSupplierFormReturned({ data: result.data }));
        else yield put(postSupplierFormReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchSubmitSupplierForm() {
    yield takeLatest(POST_SUPPLIER_FORM, performSubmitSupplierForm);
}
