import actions from "../../actions";
import { combineReducers } from "redux";

const { SET_PAGE_TITLE } = actions;

// const title = Boolean(JSON.parse(sessionStorage.getItem("epcWebsiteisRTL")))
//   ? "مركز الإمارات للسياسات"
//   : "EPC| Emirates Policy Center";

export const pageTitle = (state = "", action) => {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return action.title;

        default:
            return state;
    }
};

export default combineReducers({
    pageTitle,
});
