import axios from "axios";

const newUrlsShortlist = [
    "/",
    "/home",
    "/brief",
    "/scenarios",
    "/ExpertComment",
    "/publications",
    "/infographics",
    "/inforgraphics",
    "/Sections",
    "/academy",
    "/event",
    "/media/news",
    "/media/photos",
    "/media/videos",
    "/rss/rss-feed",
    "/about-us/about-epc",
    "/about-us/president-message",
    "/about-us/researchers",
    "/about-us/partners",
    "/about-us/careers",
    "/about-us/contact-us",
    "/privacyPolicy",
];

const urlIsInAShortlist = (url) => {
    const urlParts = url.split("/").filter(Boolean);
    if (["en", "ar"].includes(urlParts[0])) {
        urlParts.shift();
    }
    return newUrlsShortlist.includes("/" + urlParts.join("/"));
};

async function convertLegacyURL(oldUrl) {
    if (urlIsInAShortlist(oldUrl)) return oldUrl;

    const urlParts = oldUrl.split("/").filter(Boolean);
    if (urlParts.length === 0) return oldUrl;
    if (urlParts.length === 1 && ["en", "ar"].includes(urlParts[0]))
        return oldUrl;

    const response = await axios
        .get(`/api/url/pair`, { params: { oldUrl } })
        .catch(() => ({ data: { success: false } }));

    if (!response.data.success || !response.data.newUrl) return oldUrl;
    else return response.data.newUrl;
}

function addLanguageToUrl(url, currentLanguage) {
    const urlParts = url.split("/").filter(Boolean);
    if (urlParts[0] !== "en" && urlParts[0] !== "ar") {
        urlParts.unshift(currentLanguage);
    }
    return "/" + urlParts.join("/");
}

function resolveHome(url) {
    if (url === "/en") return "/en/home";
    if (url === "/ar") return "/ar/home";
    return url;
}

function resolveIfographics(url) {
    return (
        "/" +
        url
            .split("/")
            .filter(Boolean)
            .map((el) =>
                el.toLowerCase() === "inforgraphics" ? "infographics" : el
            )
            .join("/")
    );
}

async function resolveDetails(url, { languageSwitch }) {
    const urlParts = url.split("/").filter(Boolean);
    if (urlParts[1] !== "details") return url;
    const language = urlParts[0];
    const alias = urlParts[urlParts.length - 1];
    const type = urlParts[urlParts.length - 2];
    const response = await axios
        .get(`/api/post/${alias}`, { headers: { language } })
        .catch(() => ({ data: { success: false } }));

    if (response.data.success && response.data.id) {
        return `/${language}/details/${response.data.mainTypeAlias}/${alias}`;
    }
    if (languageSwitch) {
        if (
            [
                "publications",
                "news",
                "brief",
                "scenario",
                "infographics",
            ].includes(urlParts[2])
        ) {
            return `/${language}/${urlParts[2]}`;
        } else if (urlParts[2] === "featured") {
            return `/${language}/features/all`;
        } else {
            return `/${language}/home`;
        }
    }

    const complementLanguage = language === "ar" ? "en" : "ar";
    const complementResponse = await axios
        .get(`/api/post/${alias}`, {
            headers: { language: complementLanguage },
        })
        .catch(() => ({ data: { success: false } }));

    if (
        complementResponse.data.success &&
        complementResponse.data.id &&
        response.data.mainTypeAlias
    ) {
        return `/${complementLanguage}/details/${response.data.mainTypeAlias}/${alias}`;
    } else if (
        complementResponse.data.success &&
        complementResponse.data.id &&
        !response.data.mainTypeAlias
    ) {
        return `/${complementLanguage}/details/${type}/${alias}`;
    }

    return `/${language}/not-found`;
}

async function resolveEvent(url) {
    const urlParts = url.split("/").filter(Boolean);
    if (urlParts[1] !== "event" && urlParts[1] !== "events") return url;
    if (urlParts.length === 2) return url;

    let language = urlParts[0];
    let alias = urlParts[urlParts.length - 1];

    const aliasParts = alias.split("-");
    if (["en", "ar"].includes(aliasParts[aliasParts.length - 1])) {
        language = aliasParts.pop();
    }
    alias = aliasParts.join("-");

    return `/${language}/event/${alias}`;
}

function extractLanguageFromUrl(url) {
    const regExpResilt = url.match(/^\/(ar|en)\/.*$/);
    if (!regExpResilt) return "ar";
    return regExpResilt[1];
}

export async function buildCanonicalUrl(currentUrl, currentLanguage) {
    const languageSwitch = Boolean(sessionStorage.getItem("language-switch"));
    sessionStorage.removeItem("language-switch");

    let canonicalUrl = currentUrl;
    canonicalUrl = await convertLegacyURL(canonicalUrl);
    canonicalUrl = addLanguageToUrl(canonicalUrl, currentLanguage);
    canonicalUrl = resolveHome(canonicalUrl);
    canonicalUrl = resolveIfographics(canonicalUrl);
    canonicalUrl = await resolveDetails(canonicalUrl, { languageSwitch });
    canonicalUrl = await resolveEvent(canonicalUrl);

    const canonicalLanguage = extractLanguageFromUrl(canonicalUrl);

    return { canonicalUrl, canonicalLanguage };
}

export async function switchLanguages() {
    const url = window.location.pathname;
    const urlParts = url.split("/").filter(Boolean);
    const currentLanguage = urlParts[0];
    if (currentLanguage == "ar")
        sessionStorage.setItem("SUBSCRIBE_FORM_AR", "true");
    if (currentLanguage == "en")
        sessionStorage.setItem("SUBSCRIBE_FORM_EN", "true");
}

export function replaceLaguage() {
    const url = window.location.pathname;
    const urlParts = url.split("/").filter(Boolean);
    const currentLanguage = urlParts[0];
    const targetLanguage = currentLanguage === "en" ? "ar" : "en";
    urlParts[0] = targetLanguage;
    sessionStorage.setItem("language-switch", true);
    return "/" + urlParts.join("/");
}
