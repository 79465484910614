import { postRAKData, postRakServicesData, getRAKData } from "./api";
import axios from "axios";

export const businessDirectorySearch = async (data) =>
    await postRAKData("business_directory/search", data, null);

export const fetchEServicesDetails = async (data) =>
    await postRakServicesData(`services/details`, data, null);

export const cooVerification = async (data) =>
    await postRAKData(`coo_ws/verify`, data, null);

export const cooVerificationData = async (data) =>
    await getRAKData(`coo_ws/verify`, data, null);

export const ratificationVerification = async (data) =>
    await getRAKData(`ratification/verify`, data, null);

export const serviceLogin = async (data) =>
    data.loginType
        ? await postRAKData(
              `${data.loginType === 1 ? `member_user` : `personal_user`}/login`,
              data,
              null
          )
        : null;

export const getSelectMenuData = async () => {
    let coo_type = await getRAKData("coo_ws/coo_type");
    let currency = await getRAKData("list/currency");
    let country = await getRAKData("list/country");
    let transportation = await getRAKData("list/transportation");
    let uom = await getRAKData("list/uom");
    let hsItems = await getRAKData("list/hsItems");
    let legal_status = await getRAKData("list/legal_status");
    let classification = await getRAKData("list/classification");
    let domain = await getRAKData("list/domain");
    let iactivity = await getRAKData("list/iactivity");
    return {
        coo_type: coo_type.items || [],
        currency: currency.items || [],
        country: country.items || [],
        transportation: transportation.items || [],
        uom: uom.items || [],
        hsItems: hsItems.items || [],
        legal_status: legal_status.items || [],
        classification: classification.items || [],
        domain: domain.items || [],
        iactivity: iactivity.items || [],
        // networkSuccess:
        //   coo_type.networkSuccess &&
        //   currency.networkSuccess &&
        //   country.networkSuccess &&
        //   transportation.networkSuccess &&
        //   uom.networkSuccess &&
        //   hsItems.networkSuccess,
    };
};

export const calculateFees = async (data) =>
    await getRAKData(`coo_ws/fees/${data.invoiceValue}/${data.currency}`);

export const newCoo = async (data) =>
    await postRAKData(`coo_ws/coo_request`, data, null);

export const uploadFileRak = async (membership_code, body, header) => {
    let headers = {
        ...header,
        "Content-Type": `multipart/form-data; boundary=----WebKitFormBoundarylm2BMGORIHfK8qmN`,
    };

    const url = ``;

    return await axios
        .post(encodeURI(url), body, { headers })
        .then((res) => {
            if (res.status === 200 /* && res.data.successUpload*/) {
                return { networkSuccess: true, res: { ...res.data } };
            } else {
                return { networkSuccess: false, res: { ...res.data } || {} };
            }
        })
        .catch((e) => {
            return { networkSuccess: false, res: {} };
        });
};

export const getRakRequestsList = async (data) =>
    await getRAKData(`view_all_req_list/pending_request_list`, data, null);

export const getCooAccreditedList = async (data) =>
    await getRAKData(`list/cooAccreditedList`, data, null);

export const cooAdditionalRequest = async (data) =>
    await postRAKData(`coo_ws/coo_additional_request`, data, null);

export const cooEditRequest = async (data) =>
    await postRAKData(`coo_edit_request/cooEdit`, data, null);

export const goodsDetailsList = async (data) =>
    await getRAKData(`coo_edit_request/goods_details_list`, data, null);

export const cooEditGoodsDetail = async (data) =>
    await postRAKData(`coo_edit_request/editCooGoodsDetail`, data, null);

export const ratificationType = async () =>
    await getRAKData(`ratification/ratification_type`, null, null);

export const ratificationDocType = async () =>
    await getRAKData(`list/ratification_doc_type`, null, null);

export const ratificationRequest = async (data) =>
    await postRAKData(`ratification/ratification_requests`, data, null);

export const membershipVerification = async (data) =>
    await getRAKData(
        `membership/verify/${data.code}/${data.CompanyDate}`,
        null,
        true
    );

export const membershipFees = async (data) =>
    await postRAKData(`membership/fees_updated`, data, true);
