import actions from "../../actions";
import { combineReducers } from "redux";

const { LIST_ALL_UNITS, ALL_UNITS_RETURNED } = actions;

export const getAllUnits = (state = false, action) => {
    switch (action.type) {
        case LIST_ALL_UNITS:
            return true;
        case ALL_UNITS_RETURNED:
            return false;
        default:
            return state;
    }
};

export const allUnitsReturned = (state = false, action) => {
    switch (action.type) {
        case ALL_UNITS_RETURNED:
            return action.data;

        default:
            return state;
    }
};

export default combineReducers({
    getAllUnits,
    allUnitsReturned,
});
