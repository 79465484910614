import actions from "../../actions";
import { combineReducers } from "redux";

const {
    DO_SEARCH,
    DO_SEARCH_RETURNED,
    DO_SEARCH_ACADEMY_RETURNED,
    SEARCH_BY_TYPE,
    SEARCH_BY_TYPE_RETURNED,
} = actions;

export const searchData = (state = false, action) => {
    switch (action.type) {
        case DO_SEARCH:
            return action.data;
        default:
            return state;
    }
};
export const searchDataReturned = (state = false, action) => {
    switch (action.type) {
        case DO_SEARCH_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export const searchAcademyReturned = (state = false, action) => {
    switch (action.type) {
        case DO_SEARCH_ACADEMY_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export const searchByType = (state = false, action) => {
    switch (action.type) {
        case SEARCH_BY_TYPE:
            return true;
        case SEARCH_BY_TYPE_RETURNED:
            return false;
        default:
            return state;
    }
};

export const searchTypeReturned = (state = false, action) => {
    switch (action.type) {
        case SEARCH_BY_TYPE_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export default combineReducers({
    searchData,
    searchDataReturned,
    searchAcademyReturned,
    searchByType,
    searchTypeReturned,
});
