import { put, takeLatest, call } from "redux-saga/effects";
import {
    businessDirectorySearch,
    fetchEServicesDetails,
    cooVerification,
    ratificationVerification,
    serviceLogin,
    getSelectMenuData,
    calculateFees,
    newCoo,
    uploadFileRak,
    getRakRequestsList,
    cooVerificationData,
    getCooAccreditedList,
    cooAdditionalRequest,
    cooEditRequest,
    goodsDetailsList,
    cooEditGoodsDetail,
    ratificationType,
    ratificationDocType,
    ratificationRequest,
    membershipVerification,
    membershipFees,
} from "../../network/APIServices";
import actions from "../../actions";
import { push } from "connected-react-router";
import { store } from "../../store";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const {
    POST_BUSINESS_DIRECTORY_FORM,
    postBusinessDirectoryFormReturned,
    GET_ESERVICES_DETAILS,
    getEservicesDetailsReturned,
    POST_COO_VERIFY_FORM,
    postCooVerifyFormReturned,
    GET_RATIFICATION_VERIFY,
    getRatificationVerifyReturned,
    SERVICE_LOGIN,
    serviceLoginDone,
    FETCH_SELECT_MENU_DATA,
    fetchSelectMenuDataDone,
    CALCULATE_FEES,
    calculateFeesDone,
    SAVE_NEW_COO_DATA,
    saveNewCooDataDone,
    UPLOAD_RAK_FILE,
    uploadRakFileDone,
    GET_RAK_REQUESTS_LIST,
    getRakRequestsListDone,
    GET_COO_VERIFY_DATA,
    getCooVerifyDataReturned,
    GET_COO_ACCREDITED_LIST,
    getCooAccreditedListDone,
    SEND_COO_ADDITIONAL_REQUEST,
    sendCooAdditionalRequestDone,
    SEND_COO_EDIT_REQUEST,
    sendCooEditRequestDone,
    GET_GOODS_DETAILS_LIST,
    getGoodsDetailsListDone,
    SEND_COO_EDIT_GOODS,
    sendCooEditGoodsDone,
    GET_RATIFICATION_TYPE,
    getRatificationTypeDone,
    GET_RATIFICATION_DOC_TYPE,
    getRatificationDocTypeDone,
    SEND_RATIFICATION_REQUEST,
    sendRatificationRequestDone,
    GET_MEMBERSHIP_VERIFY,
    getMembershipVerifyDone,
    GET_MEMBERSHIP_FEES,
    getMembershipFeesDone,
} = actions;

function* performUploadRakFile({ data, body }) {
    try {
        const result = yield call(uploadFileRak, data, body);

        if (result) yield put(uploadRakFileDone({ data: result }));
        else yield put(uploadRakFileDone({ data: [] }));
    } catch (error) {
        console.error(error);
        yield put(uploadRakFileDone({ data: [] }));
    }
}
export function* watchUploadRakFile() {
    yield takeLatest(UPLOAD_RAK_FILE, performUploadRakFile);
}

function* performSubmitBusinessDirectoryForm(action) {
    try {
        const data = action.data;

        const result = yield call(businessDirectorySearch, data);
        console.log(result, "data");

        if (result)
            yield put(
                postBusinessDirectoryFormReturned({ data: result.items })
            );
        else yield put(postBusinessDirectoryFormReturned({ data: [] }));
    } catch (error) {
        console.error(error);
        yield put(postBusinessDirectoryFormReturned({ data: [] }));
    }
}

export function* watchSubmitBusinessDirectoryForm() {
    yield takeLatest(
        POST_BUSINESS_DIRECTORY_FORM,
        performSubmitBusinessDirectoryForm
    );
}

function* performEservicesDetails(action) {
    try {
        const data = action.data;
        const result = yield call(fetchEServicesDetails, data);

        if (result && result.networkSuccess)
            yield put(getEservicesDetailsReturned({ data: result }));
        else yield put(getEservicesDetailsReturned({ data: [] }));
    } catch (error) {
        console.error(error);
        yield put(getEservicesDetailsReturned({ data: [] }));
    }
}

export function* watchEservicesDetails() {
    yield takeLatest(GET_ESERVICES_DETAILS, performEservicesDetails);
}

function* performCooVerification(action) {
    try {
        const data = action.data;
        const result = yield call(cooVerification, data);

        if (result && result.networkSuccess)
            yield put(postCooVerifyFormReturned({ data: result }));
        else yield put(postCooVerifyFormReturned({ data: [] }));
    } catch (error) {
        console.error(error);
        yield put(postCooVerifyFormReturned({ data: [] }));
    }
}

export function* watchCooVerification() {
    yield takeLatest(POST_COO_VERIFY_FORM, performCooVerification);
}

function* performRatificationVerification(action) {
    try {
        const data = action.data;
        const result = yield call(ratificationVerification, data);

        if (result && result.networkSuccess)
            yield put(getRatificationVerifyReturned({ data: result }));
        else yield put(getRatificationVerifyReturned({ data: [] }));
    } catch (error) {
        console.error(error);
        yield put(getRatificationVerifyReturned({ data: [] }));
    }
}

export function* watchRatificationVerification() {
    yield takeLatest(GET_RATIFICATION_VERIFY, performRatificationVerification);
}

function* performServiceLogin({ user, password, loginType }) {
    const data = { user_name: user, password: password, loginType };
    try {
        const result = yield call(serviceLogin, data);

        if (result && result.networkSuccess) {
            if (result.error) {
                yield put(serviceLoginDone({ status: 0 }));
                sessionStorage.setItem("loggedType", 0);
                if (result.error == 106) {
                    MySwal.fire({
                        icon: "error",
                        title: "Invalid Username or Email",
                    });
                } else if (result.error == 103) {
                    MySwal.fire({
                        icon: "error",
                        title: "Invalid Password",
                    });
                }
            } else {
                yield put(serviceLoginDone({ status: loginType }));
                sessionStorage.setItem("loggedType", loginType);
                sessionStorage.setItem(
                    "serviceProfile",
                    JSON.stringify(result.user[0])
                );
                store.dispatch(push("/services-form/requests-list"));
            }
        } else {
            yield put(serviceLoginDone({ status: 0 }));
            sessionStorage.setItem("loggedType", 0);
        }
    } catch (error) {
        console.error(error);
        yield put(serviceLoginDone({ status: 0 }));
        sessionStorage.setItem("loggedType", 0);
    }
}

export function* watchServiceLogin() {
    yield takeLatest(SERVICE_LOGIN, performServiceLogin);
}

function* performFetchSelectMenuData() {
    try {
        const result = yield call(getSelectMenuData);

        if (result) {
            yield put(fetchSelectMenuDataDone({ data: { ...result } }));
        } else {
            yield put(fetchSelectMenuDataDone({ data: {} }));
        }
    } catch (error) {
        console.error(error);
        yield put(fetchSelectMenuDataDone({ data: {} }));
    }
}

export function* watchFetchSelectMenuData() {
    yield takeLatest(FETCH_SELECT_MENU_DATA, performFetchSelectMenuData);
}

function* performCalculateFees({ data }) {
    try {
        const result = yield call(calculateFees, data);

        if (result && result.networkSuccess) {
            yield put(calculateFeesDone({ data: { ...result } }));
        } else {
            yield put(calculateFeesDone({ data: {} }));
        }
    } catch (error) {
        console.error(error);
        yield put(calculateFeesDone({ data: {} }));
    }
}

export function* watchCalculateFees() {
    yield takeLatest(CALCULATE_FEES, performCalculateFees);
}

function* performSendNewCooData({ data }) {
    try {
        const result = yield call(newCoo, data);

        if (result && result.networkSuccess) {
            yield put(saveNewCooDataDone({ data: { ...result } }));
        } else {
            yield put(saveNewCooDataDone({ data: {} }));
        }
    } catch (error) {
        console.error(error);
        yield put(saveNewCooDataDone({ data: {} }));
    }
}

export function* watchSendNewCooData() {
    yield takeLatest(SAVE_NEW_COO_DATA, performSendNewCooData);
}

function* performGetRakRequestsList(action) {
    try {
        const data = action.data;
        const result = yield call(getRakRequestsList, data);

        if (result && result.networkSuccess) {
            yield put(getRakRequestsListDone({ data: { ...result } }));
        } else {
            yield put(getRakRequestsListDone({ data: {} }));
        }
    } catch (error) {
        console.error(error);
        yield put(getRakRequestsListDone({ data: {} }));
    }
}

export function* watchGetRakRequestsList() {
    yield takeLatest(GET_RAK_REQUESTS_LIST, performGetRakRequestsList);
}

function* performCooVerifyData(action) {
    try {
        const data = action.data;
        const result = yield call(cooVerificationData, data);

        if (result && result.networkSuccess)
            yield put(getCooVerifyDataReturned({ data: result }));
        else yield put(getCooVerifyDataReturned({ data: [] }));
    } catch (error) {
        console.error(error);
        yield put(getCooVerifyDataReturned({ data: [] }));
    }
}

export function* watchCooVerifyData() {
    yield takeLatest(GET_COO_VERIFY_DATA, performCooVerifyData);
}

function* performCooAccreditedList(action) {
    try {
        const data = action.data;
        const result = yield call(getCooAccreditedList, data);

        if (result && result.networkSuccess)
            yield put(getCooAccreditedListDone({ data: result }));
        else yield put(getCooAccreditedListDone({ data: [] }));
    } catch (error) {
        console.error(error);
        yield put(getCooAccreditedListDone({ data: [] }));
    }
}

export function* watchCooAccreditedList() {
    yield takeLatest(GET_COO_ACCREDITED_LIST, performCooAccreditedList);
}

function* performCooAdditionalRequest(action) {
    try {
        const data = action.data;
        const result = yield call(cooAdditionalRequest, data);
        if (result && result.networkSuccess)
            yield put(sendCooAdditionalRequestDone({ data: result }));
        else {
            yield put(sendCooAdditionalRequestDone({ data: [] }));
            MySwal.fire({
                icon: "error",
                title: "Invalid Request",
            });
        }
    } catch (error) {
        console.error(error);
        yield put(sendCooAdditionalRequestDone({ data: [] }));
    }
}

export function* watchCooAdditionalRequest() {
    yield takeLatest(SEND_COO_ADDITIONAL_REQUEST, performCooAdditionalRequest);
}

function* performCooEditRequest(action) {
    try {
        const data = action.data;
        const result = yield call(cooEditRequest, data);
        if (result && result.networkSuccess)
            yield put(sendCooEditRequestDone({ data: result }));
        else {
            yield put(sendCooEditRequestDone({ data: [] }));
            MySwal.fire({
                icon: "error",
                title: "Invalid Request",
            });
        }
    } catch (error) {
        console.error(error);
        yield put(sendCooEditRequestDone({ data: [] }));
    }
}

export function* watchCooEditRequest() {
    yield takeLatest(SEND_COO_EDIT_REQUEST, performCooEditRequest);
}

function* performGoodsDetailsList(action) {
    try {
        const data = action.data;
        const result = yield call(goodsDetailsList, data);
        if (result && result.networkSuccess)
            yield put(getGoodsDetailsListDone({ data: result }));
        else {
            yield put(getGoodsDetailsListDone({ data: [] }));
        }
    } catch (error) {
        console.error(error);
        yield put(getGoodsDetailsListDone({ data: [] }));
    }
}

export function* watchGoodsDetailsList() {
    yield takeLatest(GET_GOODS_DETAILS_LIST, performGoodsDetailsList);
}

function* performCooGoodsEditRequest(action) {
    try {
        const data = action.data;
        const result = yield call(cooEditGoodsDetail, data);
        if (result && result.networkSuccess)
            yield put(sendCooEditGoodsDone({ data: result }));
        else {
            yield put(sendCooEditGoodsDone({ data: [] }));
            MySwal.fire({
                icon: "error",
                title: "Invalid Request",
            });
        }
    } catch (error) {
        console.error(error);
        yield put(sendCooEditGoodsDone({ data: [] }));
    }
}

export function* watchCooGoodsEditRequest() {
    yield takeLatest(SEND_COO_EDIT_GOODS, performCooGoodsEditRequest);
}

function* performRatificationType(action) {
    try {
        const data = action.data;
        const result = yield call(ratificationType, data);
        if (result && result.networkSuccess && result.items)
            yield put(getRatificationTypeDone({ data: result.items }));
        else {
            yield put(getRatificationTypeDone({ data: [] }));
        }
    } catch (error) {
        console.error(error);
        yield put(getRatificationTypeDone({ data: [] }));
    }
}

export function* watchRatificationType() {
    yield takeLatest(GET_RATIFICATION_TYPE, performRatificationType);
}

function* performRatificationDocType(action) {
    try {
        const data = action.data;
        const result = yield call(ratificationDocType, data);
        if (result && result.networkSuccess && result.items)
            yield put(getRatificationDocTypeDone({ data: result.items }));
        else {
            yield put(getRatificationDocTypeDone({ data: [] }));
        }
    } catch (error) {
        console.error(error);
        yield put(getRatificationDocTypeDone({ data: [] }));
    }
}

export function* watchRatificationDocType() {
    yield takeLatest(GET_RATIFICATION_DOC_TYPE, performRatificationDocType);
}

function* performSendRatificationRequest(action) {
    try {
        const data = action.data;
        const result = yield call(ratificationRequest, data);

        if (result && result.networkSuccess && result.rowcode)
            yield put(sendRatificationRequestDone({ data: result.items }));
        else {
            yield put(sendRatificationRequestDone({ data: [] }));
        }
    } catch (error) {
        console.error(error);
        yield put(sendRatificationRequestDone({ data: [] }));
    }
}

export function* watchSendRatificationRequest() {
    yield takeLatest(SEND_RATIFICATION_REQUEST, performSendRatificationRequest);
}

function* performVerfiyMembership(action) {
    try {
        const data = action.data;
        const result = yield call(membershipVerification, data);

        if (
            result &&
            result.networkSuccess &&
            result.activities &&
            result.company_profile
        )
            yield put(getMembershipVerifyDone({ data: result }));
        else {
            yield put(getMembershipVerifyDone({ data: [] }));
        }
    } catch (error) {
        console.error(error);
        yield put(getMembershipVerifyDone({ data: [] }));
    }
}

export function* watchVerfiyMembership() {
    yield takeLatest(GET_MEMBERSHIP_VERIFY, performVerfiyMembership);
}

function* performMembershipFees(action) {
    try {
        const data = action.data;
        const result = yield call(membershipFees, data);
        if (result && result.networkSuccess && result.items)
            yield put(getMembershipFeesDone({ data: result }));
        else {
            yield put(getMembershipFeesDone({ data: [] }));
        }
    } catch (error) {
        console.error(error);
        yield put(getMembershipFeesDone({ data: [] }));
    }
}

export function* watchMembershipFees() {
    yield takeLatest(GET_MEMBERSHIP_FEES, performMembershipFees);
}
