import { createAction } from "../creators";

export default {
    ...createAction("IS_LOADING"),
    ...createAction("LOADING_DONE"),

    ...createAction("POST_BUSINESS_DIRECTORY_FORM", "data"),
    ...createAction("POST_BUSINESS_DIRECTORY_FORM_RETURNED", "data"),
    ...createAction("GET_ESERVICES_DETAILS", "data"),
    ...createAction("GET_ESERVICES_DETAILS_RETURNED", "data"),
    ...createAction("POST_COO_VERIFY_FORM", "data"),
    ...createAction("POST_COO_VERIFY_FORM_RETURNED", "data"),
    ...createAction("GET_RATIFICATION_VERIFY", "data"),
    ...createAction("GET_RATIFICATION_VERIFY_RETURNED", "data"),

    ...createAction("SERVICE_LOGIN", "user", "password", "loginType"),
    ...createAction("SERVICE_LOGIN_DONE", "status"),

    ...createAction("FETCH_SELECT_MENU_DATA"),
    ...createAction("FETCH_SELECT_MENU_DATA_DONE", "data"),

    ...createAction("CALCULATE_FEES", "data"),
    ...createAction("CALCULATE_FEES_DONE", "data"),

    ...createAction("SAVE_NEW_COO_DATA", "data"),
    ...createAction("SAVE_NEW_COO_DATA_DONE", "data"),

    ...createAction("UPLOAD_RAK_FILE", "data", "body"),
    ...createAction("UPLOAD_RAK_FILE_DONE", "data"),

    ...createAction("GET_RAK_REQUESTS_LIST", "data"),
    ...createAction("GET_RAK_REQUESTS_LIST_DONE", "data"),

    ...createAction("GET_COO_VERIFY_DATA", "data"),
    ...createAction("GET_COO_VERIFY_DATA_RETURNED", "data"),

    ...createAction("GET_COO_ACCREDITED_LIST", "data"),
    ...createAction("GET_COO_ACCREDITED_LIST_DONE", "data"),

    ...createAction("SEND_COO_ADDITIONAL_REQUEST", "data"),
    ...createAction("SEND_COO_ADDITIONAL_REQUEST_DONE", "data"),

    ...createAction("SEND_COO_EDIT_REQUEST", "data"),
    ...createAction("SEND_COO_EDIT_REQUEST_DONE", "data"),

    ...createAction("SEND_COO_EDIT_GOODS", "data"),
    ...createAction("SEND_COO_EDIT_GOODS_DONE", "data"),

    ...createAction("GET_GOODS_DETAILS_LIST", "data"),
    ...createAction("GET_GOODS_DETAILS_LIST_DONE", "data"),

    ...createAction("GET_RATIFICATION_TYPE"),
    ...createAction("GET_RATIFICATION_TYPE_DONE", "data"),

    ...createAction("GET_RATIFICATION_DOC_TYPE"),
    ...createAction("GET_RATIFICATION_DOC_TYPE_DONE", "data"),

    ...createAction("SEND_RATIFICATION_REQUEST", "data"),
    ...createAction("SEND_RATIFICATION_REQUEST_DONE", "data"),

    ...createAction("GET_MEMBERSHIP_VERIFY", "data"),
    ...createAction("GET_MEMBERSHIP_VERIFY_DONE", "data"),

    ...createAction("GET_MEMBERSHIP_FEES", "data"),
    ...createAction("GET_MEMBERSHIP_FEES_DONE", "data"),
};
