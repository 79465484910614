import React, { memo, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { RiArrowUpSLine } from "react-icons/ri";
import useStyles from "../../styles/routes/shared/toTop";

function ToTop() {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        window.addEventListener(
            "scroll",
            function () {
                let fromTop = document.documentElement.scrollTop;

                if (fromTop <= 50) setVisible(false);
                if (fromTop >= 120) setVisible(true);
            },
            false
        );
    }, []);

    const goToTheTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const classes = useStyles();
    return (
        <Box
            className={visible ? classes.root : classes.none}
            onClick={goToTheTop}
        >
            <RiArrowUpSLine />
        </Box>
    );
}

export default memo(ToTop);
