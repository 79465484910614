import React, { useEffect } from "react";

function Spinner() {
    return (
        <div
            style={{
                position: "absolute",
                display: "flex",
                top: 90,
                bottom: 0,
                zIndex: 1099,
                // backdropFilter: "blur(2px)",
                right: 0,
                // backgroundColor: "#ffffff1a",
                left: 0,
                position: "fixed",
                justifyContent: "center",
                alignItems: "center",
                background:
                    "linear-gradient(0deg, rgba(47,61,70,1) 0%, rgba(249,249,249,1) 100%)",
            }}
        />
    );
}

export default Spinner;
