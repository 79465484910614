import actions from "../../actions";
import { combineReducers } from "redux";

const {
    GET_ALL,
    ALL_RETURNED,
    GET_BY_ID,
    GET_BY_ALIAS,
    BY_ALIAS_RETURNED,
    BY_ID_RETURNED,
    GET_RESERVATIONS,
    ALL_RESERVATIONS_RETURNED,
    GET_DATA,
    DATA_RETURNED,
    SET_PAGINATION,
} = actions;

export const getAll = (state = false, action) => {
    switch (action.type) {
        case GET_ALL:
            return true;
        case ALL_RETURNED:
            return false;
        default:
            return state;
    }
};
export const allReturned = (state = [], action) => {
    switch (action.type) {
        case ALL_RETURNED:
            return {
                ...state,
                [action.data.returnedTypeName]:
                    action.data.returnedTypeName &&
                    action.data[action.data.returnedTypeName]
                        ? action.data[action.data.returnedTypeName]
                        : action?.data,
            };
        default:
            return state;
    }
};
export const getById = (state = false, action) => {
    switch (action.type) {
        case GET_BY_ID:
            return true;
        case BY_ID_RETURNED:
            return false;
        default:
            return state;
    }
};
export const byIdReturned = (state = false, action) => {
    switch (action.type) {
        case BY_ID_RETURNED:
            return action.data;
        default:
            return state;
    }
};
export const getByAlias = (state = false, action) => {
    switch (action.type) {
        case GET_BY_ALIAS:
            return true;
        case BY_ALIAS_RETURNED:
            return false;
        default:
            return state;
    }
};
export const byAliasReturned = (state = false, action) => {
    switch (action.type) {
        case BY_ALIAS_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export const getReservations = (state = false, action) => {
    switch (action.type) {
        case GET_RESERVATIONS:
            return true;
        case ALL_RESERVATIONS_RETURNED:
            return false;
        default:
            return state;
    }
};
export const allReservationsReturned = (state = [], action) => {
    switch (action.type) {
        case ALL_RESERVATIONS_RETURNED:
            return {
                ...state,
                [action.data.returnedTypeName]:
                    action.data[action.data.returnedTypeName],
            };
        default:
            return state;
    }
};

export const getData = (state = false, action) => {
    switch (action.type) {
        case GET_DATA:
            return true;
        case DATA_RETURNED:
            return false;
        default:
            return state;
    }
};
export const allData = (state = [], action) => {
    switch (action.type) {
        case DATA_RETURNED:
            return {
                ...state,
                [action.data.returnedTypeName]: action.data[
                    action.data.returnedTypeName
                ]
                    ? action.data[action.data.returnedTypeName]
                    : action.data,
                count: action.data.count,
            };
        default:
            return state;
    }
};

export const setPagination = (state = false, action) => {
    switch (action.type) {
        case SET_PAGINATION:
            return action?.data;
        default:
            return state;
    }
};
export default combineReducers({
    getAll,
    allReturned,
    getById,
    byIdReturned,
    getByAlias,
    byAliasReturned,
    getReservations,
    allReservationsReturned,
    getData,
    allData,
    setPagination,
});
