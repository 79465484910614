import React, { useState } from "react";
import jsonp from "jsonp";
import toQueryString from "to-querystring";

const getAjaxUrl = (url) => url.replace("/post?", "/post-json?");

class MailchimpSubscribe2 extends React.Component {
    state = {
        status: null,
        message: null,
        data: null,
        submited: false,
    };

    subscribe = (data) => {
        const params = toQueryString(data);
        this.setState({ ...this.state, data });
        const url = getAjaxUrl(this.props.url) + "&" + params;

        if (data.validationError) {
            this.setState({
                status: "error",
                message: `All fields with "*" is required`,
                submited: true,
            });
        } else {
            this.setState(
                {
                    status: "sending",
                    message: null,
                },
                () => {
                    jsonp(
                        url,
                        {
                            param: "c",
                        },
                        (err, data) => {
                            if (err) {
                                this.setState({
                                    status: "error",
                                    message: err,
                                });
                            } else if (data.result !== "success") {
                                this.setState({
                                    status: "error",
                                    message: data.msg,
                                });
                            } else {
                                this.setState({
                                    status: "success",
                                    message: data.msg,
                                    submited: true,
                                });
                            }
                        }
                    );
                }
            );
        }
    };
    render() {
        return this.props.render({
            subscribe: this.subscribe,
            status: this.state.status,
            message: this.state.message,
            data: this.state.data,
            submited: this.state.submited,
        });
    }
}
const MailchimpSubscribe = (props) => {
    const [submited, setSubmited] = useState(false);
    const [data, setData] = useState(null);
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(null);

    const subscribe = (data) => {
        const params = toQueryString(data);
        setData(data);

        const url = getAjaxUrl(props.url) + "&" + params;
        if (data.validationError) {
            setStatus("error");
            setMessage('All fields with "*" is required');
            setSubmited(false);
        } else {
            setStatus("sending");
            setMessage(null);

            jsonp(
                url,
                {
                    param: "c",
                },
                (err, data) => {
                    if (err) {
                        this.setState({
                            status: "error",
                            message: err,
                        });
                    } else if (data.result !== "success") {
                        setStatus("error");
                        setMessage(data.msg);
                    } else {
                        setStatus("success");
                        setMessage(data.msg);
                        setSubmited(true);
                    }
                }
            );
        }
    };

    return props.render({
        subscribe,
        message,
        data,
        submited,
        status,
    });
};

export default MailchimpSubscribe2;
