import { createAction } from "../creators";

export default {
    ...createAction("GET_ALL_POSTS", "categoryAlias", "isFeatured", "language"),
    ...createAction("ALL_POSTS_RETURNED", "data"),
    ...createAction("GET_SPECIFIC_POSTS", "data"),
    ...createAction("SPECIFIC_POSTS_RETURNED", "data"),
    ...createAction("GET_SLIDER_POSTS", "language"),
    ...createAction("SLIDER_POSTS_RETURNED", "data"),
    ...createAction("GET_SECTIONS_POSTS", "language"),
    ...createAction("SECTIONS_POSTS_RETURNED", "data"),
};
