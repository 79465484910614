import actions from "../../actions";
import { combineReducers } from "redux";

const { GET_MAP, MAP_RETURNED } = actions;

export const mapDetailsRequest = (state = false, action) => {
    switch (action.type) {
        case GET_MAP:
            return true;
        case MAP_RETURNED:
            return false;
        default:
            return state;
    }
};
export const mapDetailsReturned = (state = [], action) => {
    switch (action.type) {
        case MAP_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export default combineReducers({
    mapDetailsRequest,
    mapDetailsReturned,
});
