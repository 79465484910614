import actions from "../../actions";
import { combineReducers } from "redux";

const { SUBSCRIBE_ACTION, SUBSCRIBE_RETURNED } = actions;

export const sendSubscribe = (state = false, action) => {
    switch (action.type) {
        case SUBSCRIBE_ACTION:
            return true;
        case SUBSCRIBE_RETURNED:
            return false;
        default:
            return state;
    }
};
export const subscribeRequestReturned = (state = false, action) => {
    switch (action.type) {
        case SUBSCRIBE_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export default combineReducers({
    sendSubscribe,
    subscribeRequestReturned,
});
