import { put, takeLatest, call } from "redux-saga/effects";
import { subscribeRequest } from "../../network/subscribe";
import actions from "../../actions";

const { SUBSCRIBE_ACTION, subscribeReturned } = actions;

function* performSubscribeRequest(action) {
    try {
        let { language, ...data } = action.data;

        const result = yield call(subscribeRequest, data, { language });

        if (result) yield put(subscribeReturned({ data: result.data }));
        else yield put(subscribeReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}
export function* watchSubscribeRequest() {
    yield takeLatest(SUBSCRIBE_ACTION, performSubscribeRequest);
}
