import { createAction } from "../creators";

export default {
    ...createAction("GET_ALL", "sort", "subSort"),
    ...createAction("ALL_RETURNED", "sort", "subSort", "data"),
    ...createAction("GET_RESERVATIONS", "sort", "id"),
    ...createAction("ALL_RESERVATIONS_RETURNED", "sort", "data", "id"),
    ...createAction("GET_BY_ID", "sort", "subSort", "id"),
    ...createAction("GET_BY_ALIAS", "sort", "subSort", "alias"),
    ...createAction("BY_ID_RETURNED", "sort", "subSort", "data"),
    ...createAction("BY_ALIAS_RETURNED", "sort", "subSort", "data"),
    ...createAction("GET_DATA", "sort", "data"),
    ...createAction("DATA_RETURNED", "sort", "data"),
    ...createAction("SET_PAGINATION", "data"),
};
