import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { getMapDetails } from "../../network/map";
import actions from "../../actions";

const { GET_MAP, mapReturned } = actions;

function* performGetMap(action) {
    try {
        const result = yield call(getMapDetails);

        if (result) yield put(mapReturned({ data: result.res }));
        else yield put(mapReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetMap() {
    yield takeEvery(GET_MAP, performGetMap);
}
