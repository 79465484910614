import actions from "../../actions";
import { combineReducers } from "redux";

const {
    UPLOAD_FILE_ACTION,
    FILE_CREATED,
    DELETE_FILE_ACTION,
    FILE_DELETED,
    DOWNLOAD_FILE,
    FILE_DOWNLOADED,
} = actions;

export const uploadFile = (state = false, action) => {
    switch (action.type) {
        case UPLOAD_FILE_ACTION:
            return true;
        case FILE_CREATED:
            return false;
        default:
            return state;
    }
};

export const fileCreated = (state = false, action) => {
    switch (action.type) {
        case FILE_CREATED:
            return action.response;
        case FILE_DELETED:
            return false;
        default:
            return state;
    }
};

export const deleteFile = (state = false, action) => {
    switch (action.type) {
        case DELETE_FILE_ACTION:
            return true;
        case FILE_DELETED:
            return false;
        default:
            return state;
    }
};

export const fileDeleted = (state = false, action) => {
    switch (action.type) {
        case FILE_DELETED:
            return action.response;
        default:
            return state;
    }
};

export const downloadFile = (state = false, action) => {
    switch (action.type) {
        case DOWNLOAD_FILE:
            return true;
        case FILE_DOWNLOADED:
            return false;

        default:
            return state;
    }
};
export const downloadedFiles = (state = [], action) => {
    switch (action.type) {
        case FILE_DOWNLOADED:
            return action.response;

        default:
            return state;
    }
};

export default combineReducers({
    uploadFile,
    fileCreated,
    deleteFile,
    fileDeleted,
    downloadFile,
    downloadedFiles,
});
