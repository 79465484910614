import actions from "../../actions";
import { combineReducers } from "redux";

const {
    GET_ALL_POSTS,
    ALL_POSTS_RETURNED,
    GET_SPECIFIC_POSTS,
    SPECIFIC_POSTS_RETURNED,
    GET_SLIDER_POSTS,
    SLIDER_POSTS_RETURNED,
    GET_SECTIONS_POSTS,
    SECTIONS_POSTS_RETURNED,
} = actions;

export const getAllPosts = (state = false, action) => {
    switch (action.type) {
        case GET_ALL_POSTS:
            return true;
        case ALL_POSTS_RETURNED:
            return false;
        default:
            return state;
    }
};

export const allPostsReturned = (state = false, action) => {
    switch (action.type) {
        case ALL_POSTS_RETURNED:
            const { data } = action;
            if (data.success && data.posts.length) {
                const sort = data.posts[0].category.alias;
                return {
                    ...state,
                    [sort]: action.data,
                };
            } else {
                return state;
            }

        default:
            return state;
    }
};

export const getPostTypes = (state = false, action) => {
    switch (action.type) {
        case GET_SPECIFIC_POSTS:
            return true;
        case SPECIFIC_POSTS_RETURNED:
            return false;
        default:
            return state;
    }
};

export const allPostTypesReturned = (state = false, action) => {
    switch (action.type) {
        case SPECIFIC_POSTS_RETURNED:
            const { data } = action;
            if (data && data.posts.length) {
                let sort = data.posts[0].mainTypeAlias;

                if (!sort) sort = data.posts[0].type;

                return {
                    ...state,
                    [sort]: action.data,
                };
            } else {
                return state;
            }

        default:
            return state;
    }
};

export const sliderPosts = (state = false, action) => {
    switch (action.type) {
        case GET_SLIDER_POSTS:
            return true;
        case SLIDER_POSTS_RETURNED:
            return false;
        default:
            return state;
    }
};

export const sliderPostsReturned = (state = false, action) => {
    switch (action.type) {
        case SLIDER_POSTS_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export const sectionsPosts = (state = false, action) => {
    switch (action.type) {
        case GET_SECTIONS_POSTS:
            return true;
        case SECTIONS_POSTS_RETURNED:
            return false;
        default:
            return state;
    }
};

export const sectionsPostsReturned = (state = false, action) => {
    switch (action.type) {
        case SECTIONS_POSTS_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export default combineReducers({
    getAllPosts,
    allPostsReturned,
    getPostTypes,
    allPostTypesReturned,
    sliderPosts,
    sliderPostsReturned,
    sectionsPosts,
    sectionsPostsReturned,
});
