import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import {
    getAllPosts,
    getSpecificPost,
    fetchSLiderPosts,
    fetchSectionsPosts,
} from "../../network/post";
import actions from "../../actions";

const {
    GET_ALL_POSTS,
    allPostsReturned,
    GET_SPECIFIC_POSTS,
    specificPostsReturned,
    GET_SLIDER_POSTS,
    sliderPostsReturned,
    GET_SECTIONS_POSTS,
    sectionsPostsReturned,
} = actions;

function* performGetAllPosts(action) {
    try {
        const { categoryAlias, isFeatured, language, sort } = action;

        const result = yield call(
            getAllPosts,
            isFeatured,
            categoryAlias,
            language
        );

        if (result) yield put(allPostsReturned({ data: result.res }));
        else yield put(allPostsReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetAllPosts() {
    yield takeEvery(GET_ALL_POSTS, performGetAllPosts);
}

function* performGetPostTypes(action) {
    try {
        const { data } = action;

        const result = yield call(getSpecificPost, data);

        if (result) yield put(specificPostsReturned({ data: result.res }));
        else yield put(specificPostsReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetPostTypes() {
    yield takeEvery(GET_SPECIFIC_POSTS, performGetPostTypes);
}

function* performGetSliderPosts(action) {
    try {
        const { language } = action;

        const result = yield call(fetchSLiderPosts, language);

        if (result) yield put(sliderPostsReturned({ data: result.res }));
        else yield put(sliderPostsReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetSliderPosts() {
    yield takeEvery(GET_SLIDER_POSTS, performGetSliderPosts);
}

function* performGetSectionsPosts(action) {
    try {
        const { language } = action;

        const result = yield call(fetchSectionsPosts, language);

        if (result) yield put(sectionsPostsReturned({ data: result.res }));
        else yield put(sectionsPostsReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetSectionsPosts() {
    yield takeEvery(GET_SECTIONS_POSTS, performGetSectionsPosts);
}
