import posts from "./groups/post";
import contactus from "./groups/contactus";
import users from "./groups/users";
import careers from "./groups/careers";
import files from "./groups/file";
import theme from "./groups/theme";
import services from "./groups/services";
import category from "./groups/category";
import APIServices from "./groups/APIServices";
import links from "./groups/link";
import surveys from "./groups/survey";
import comment from "./groups/comment";
import request from "./groups/request";
import rate from "./groups/rate";
import search from "./groups/search";
import crudActions from "./groups/crudActions";
import menu from "./groups/menu";
import subscribe from "./groups/subscribe";
import units from "./groups/units";
import map from "./groups/map";
import tabTitle from "./groups/tabTitle";

export default {
    ...posts,
    ...contactus,
    ...users,
    ...careers,
    ...files,
    ...theme,
    ...services,
    ...category,
    ...APIServices,
    ...links,
    ...surveys,
    ...comment,
    ...request,
    ...rate,
    ...search,
    ...crudActions,
    ...menu,
    ...subscribe,
    ...units,
    ...map,
    ...tabTitle,
    // ...category,
    // ...smtpConfigs,
    // ...generalConfigs,
    // ...file,
    // ...fileSet,
    // ...survey,
    // ...menus,
    // ...theme,
};
