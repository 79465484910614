import { fork } from "redux-saga/effects";
import * as posts from "./groups/post";
import * as contactus from "./groups/contactus";
import * as users from "./groups/users";
import * as careers from "./groups/careers";
import * as files from "./groups/file";
import * as services from "./groups/services";
import * as category from "./groups/category";
import * as links from "./groups/link";
import * as surveys from "./groups/survey";
import * as comment from "./groups/comment";
import * as request from "./groups/request";
import * as APIServices from "./groups/APIServices";
import * as rate from "./groups/rate";
import * as search from "./groups/search";
import * as crudSagas from "./groups/crud";
import * as menu from "./groups/menu";
import * as subscribe from "./groups/subscribe";
import * as units from "./groups/units";
import * as map from "./groups/map";

const sagas = Object.values({
    ...posts,
    ...contactus,
    ...users,
    ...careers,
    ...files,
    ...services,
    ...category,
    ...APIServices,
    ...links,
    ...surveys,
    ...comment,
    ...request,
    ...rate,
    ...search,
    ...crudSagas,
    ...menu,
    ...subscribe,
    ...units,
    ...map,
});

export default function* rootSaga(getState) {
    for (let index = 0; index < sagas.length; index++) {
        yield fork(sagas[index]);
    }
}
