import { fetchData, postData } from "./api";
let lang = localStorage.getItem("lang");

export const getCategories = async (type, language, limit, offset) =>
    await fetchData(`/api/category`, { type, limit, offset }, { language });

export const getCategoryById = async (id, language) =>
    await fetchData(`/api/category/${id}`, null, { language });

export const getCategoryByAlias = async (alias, language) =>
    await fetchData(`/api/category/${alias}`, null, { language });

export const getCategoryTreeByAlias = async (alias) =>
    await fetchData(`/api/category/${alias}/tree`, null, null);
