import { put, takeLatest, call } from "redux-saga/effects";
import { search, searchAcademy } from "../../network/search";
import actions from "../../actions";

const {
    DO_SEARCH,
    doSearchReturned,
    DO_SEARCH_ACADEMY,
    doSearchAcademyReturned,
    SEARCH_BY_TYPE,
    searchByTypeReturned,
} = actions;

function* performSearch(action) {
    try {
        let { data, language } = action;
        const result = yield call(search, data, language);

        if (result)
            yield put(
                doSearchReturned({
                    data: result.res,
                    limit: action.limit ? action?.limit : null,
                    offset: action.offet ? action?.offset : null,
                })
            );
        else yield put(doSearchReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}
export function* watchSearch() {
    yield takeLatest(DO_SEARCH, performSearch);
}

function* performSearchAcademy(action) {
    try {
        let { data, language } = action;

        const result = yield call(searchAcademy, data, language);

        if (result) yield put(doSearchAcademyReturned({ data: result.res }));
        else yield put(doSearchAcademyReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}
export function* watchSearchAcademy() {
    yield takeLatest(DO_SEARCH_ACADEMY, performSearchAcademy);
}

function* performSearchByType(action) {
    try {
        let { data, language } = action;
        const result = yield call(search, data, language);
        if (result) yield put(searchByTypeReturned({ data: result.res }));
        else yield put(searchByTypeReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}
export function* watchSearchByType() {
    yield takeLatest(SEARCH_BY_TYPE, performSearchByType);
}
