import { fetchData } from "./api";

export const getAllPosts = async (isFeatured, categoryAlias, language) =>
    await fetchData(
        "/api/post",
        { type: "all", isFeatured, categoryAlias },
        { language }
    );

export const getSpecificPost = async (data) =>
    await fetchData("/api/post", data.query, data.headers);

export const getPostById = async (id, language) =>
    await fetchData(`/api/post/${id}`, null, { language });

export const getPostByAlias = async (alias, language) =>
    await fetchData(`/api/post/${alias}`, null, { language });

export const getPostComments = async (id) =>
    await fetchData(`/api/post/${id}/comments`);

export const fetchSLiderPosts = async (language) =>
    await fetchData("/api/post/slider", null, { language });

export const fetchSectionsPosts = async (language) =>
    await fetchData("/api/post/sections", null, { language });
