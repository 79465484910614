import { fetchData, postData } from "./api";

let token = localStorage.getItem("authUser");

export const getAll = async (sort, subSort, id) =>
    await fetchData(`/api/${sort}`, subSort ? { type: subSort } : null, {
        token,
    });

export const getById = async (sort, id) =>
    await fetchData(`/api/${sort}/${id}`, null, { token });

export const getByAlias = async (sort, alias) =>
    await fetchData(`/api/${sort}/${alias}`, null, { token });

export const getReservations = async (sort, id) =>
    await fetchData(`/api/${sort}`, { id: id });

export const getData = async (sort, data) =>
    await fetchData(`/api/${sort}`, data, {
        token,
    });
