import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../../actions";
import {
    getAll,
    getById,
    getReservations,
    getByAlias,
    getData,
} from "../../network/crud";
const {
    GET_ALL,
    allReturned,
    GET_BY_ID,
    GET_BY_ALIAS,
    byAliasReturned,
    byIdReturned,
    GET_RESERVATIONS,
    allReservationsReturned,
    GET_DATA,
    dataReturned,
} = actions;

function* performGetAll(action) {
    try {
        let sort = action.sort;
        let subSort = action.subSort;
        const result = yield call(getAll, sort, subSort);
        if (result && result.res.success) {
            yield put(allReturned({ data: result.res, sort, subSort }));
        } else {
            yield put(allReturned({ data: [] }));
        }
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetAll() {
    yield takeEvery(GET_ALL, performGetAll);
}

function* performGetById(action) {
    try {
        let id = action.id;
        let sort = action.sort;
        const result = yield call(getById, sort, id);
        let files = [];
        let links = [];
        if (result)
            yield put(
                byIdReturned({
                    data: { result: result.res, files: files, links: links },
                })
            );
        else yield put(byIdReturned({ data: [] }));
    } catch (error) {
        yield put(byIdReturned({ data: [] }));
    }
}

export function* watchGetById() {
    yield takeEvery(GET_BY_ID, performGetById);
}

function* performGetReservations(action) {
    try {
        let sort = action.sort;
        let id = action.id;

        const result = yield call(getReservations, sort, id);

        if (result && result.res.success) {
            yield put(allReservationsReturned({ data: result.res, sort, id }));
        } else {
            yield put(allReservationsReturned({ data: [] }));
        }
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetAllReservations() {
    yield takeEvery(GET_RESERVATIONS, performGetReservations);
}

function* performGetByAlias(action) {
    try {
        const { alias, sort } = action;

        const result = yield call(getByAlias, sort, alias);

        if (result) yield put(byAliasReturned({ data: result.res }));
        else yield put(byAliasReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetByAlias() {
    yield takeEvery(GET_BY_ALIAS, performGetByAlias);
}

function* performGetData(action) {
    try {
        let data = action.data;
        let sort = action.sort;
        const result = yield call(getData, sort, data);
        if (result && result.res.success) {
            yield put(dataReturned({ data: result.res, sort }));
        } else {
            yield put(dataReturned({ data: [] }));
        }
    } catch (error) {
        console.error(error);
    }
}
export function* watchGetData() {
    yield takeEvery(GET_DATA, performGetData);
}
