import { fetchData, postData } from "./api";

export const logIn = async (data) => await postData("/api/users/login", data);

export const forgotPassword = async (data) =>
    await postData("/api/users/password/forget", data);

export const resetPassword = async (data) =>
    await postData("/api/users/password/reset", data);

export const signUp = async (data) => await postData("/api/users/signUp", data);

export const userConfirm = async (data) =>
    await postData("/api/users/email/confirm", data);
