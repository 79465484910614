export const themeList = [
    {
        id: 1,
        name: "default Theme",
        isActive: true,
        createdAt: "2021-03-07T22:04:49.211Z",
        updatedAt: "2021-03-07T22:04:49.211Z",

        elements: {
            type: "light",
            direction: "ltr",
            fontFamily: { ar: "Sakkal Majalla", en: "Garamond" },
            fontSize: { xs: 12, s: 16, m: 20, lg: 24, xl: 28 },
            fontSizeG: [28, 24, 20, 16, 12],
            colors: {
                white: "#ffffff",
                primary: "#efefef",
                secondary: "#00aedb",
                tertiary: "#84754e",
                textPrimary: "#02141d",
                btn: "#00aedb",
            },
        },
    },
];
