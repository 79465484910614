import actions from "../../actions";
import { combineReducers } from "redux";

const {
    POST_BUSINESS_DIRECTORY_FORM,
    POST_BUSINESS_DIRECTORY_FORM_RETURNED,
    GET_ESERVICES_DETAILS,
    GET_ESERVICES_DETAILS_RETURNED,
    POST_COO_VERIFY_FORM,
    POST_COO_VERIFY_FORM_RETURNED,
    GET_RATIFICATION_VERIFY,
    GET_RATIFICATION_VERIFY_RETURNED,
    SERVICE_LOGIN,
    SERVICE_LOGIN_DONE,
    FETCH_SELECT_MENU_DATA,
    FETCH_SELECT_MENU_DATA_DONE,
    CALCULATE_FEES,
    CALCULATE_FEES_DONE,
    SAVE_NEW_COO_DATA,
    SAVE_NEW_COO_DATA_DONE,
    UPLOAD_RAK_FILE,
    UPLOAD_RAK_FILE_DONE,
    GET_RAK_REQUESTS_LIST_DONE,
    GET_RAK_REQUESTS_LIST,
    GET_COO_VERIFY_DATA,
    GET_COO_VERIFY_DATA_RETURNED,
    GET_COO_ACCREDITED_LIST,
    GET_COO_ACCREDITED_LIST_DONE,
    SEND_COO_ADDITIONAL_REQUEST,
    SEND_COO_ADDITIONAL_REQUEST_DONE,
    SEND_COO_EDIT_REQUEST,
    SEND_COO_EDIT_REQUEST_DONE,
    GET_GOODS_DETAILS_LIST,
    GET_GOODS_DETAILS_LIST_DONE,
    SEND_COO_EDIT_GOODS,
    SEND_COO_EDIT_GOODS_DONE,
    GET_RATIFICATION_TYPE,
    GET_RATIFICATION_TYPE_DONE,
    GET_RATIFICATION_DOC_TYPE,
    GET_RATIFICATION_DOC_TYPE_DONE,
    SEND_RATIFICATION_REQUEST,
    SEND_RATIFICATION_REQUEST_DONE,
    GET_MEMBERSHIP_VERIFY,
    GET_MEMBERSHIP_VERIFY_DONE,
    GET_MEMBERSHIP_FEES,
    GET_MEMBERSHIP_FEES_DONE,
    LOADING_DONE,
    IS_LOADING,
} = actions;

export const postBusinessDirectoryForm = (state = false, action) => {
    switch (action.type) {
        case POST_BUSINESS_DIRECTORY_FORM:
            return true;
        case POST_BUSINESS_DIRECTORY_FORM_RETURNED:
            return false;
        default:
            return state;
    }
};
export const businessDirectoryFormReturned = (state = {}, action) => {
    switch (action.type) {
        case POST_BUSINESS_DIRECTORY_FORM_RETURNED:
            return action.data;
        default:
            return state;
    }
};
export const getEServicesDetails = (state = false, action) => {
    switch (action.type) {
        case GET_ESERVICES_DETAILS:
            return true;
        case GET_ESERVICES_DETAILS_RETURNED:
            return false;
        default:
            return state;
    }
};
export const eServicesDetailsReturned = (state = {}, action) => {
    switch (action.type) {
        case GET_ESERVICES_DETAILS_RETURNED:
            return action.data;
        default:
            return state;
    }
};
export const postCooVerificationForm = (state = false, action) => {
    switch (action.type) {
        case POST_COO_VERIFY_FORM:
            return true;
        case POST_COO_VERIFY_FORM_RETURNED:
            return false;
        default:
            return state;
    }
};
export const cooVerificationFormReturned = (state = {}, action) => {
    switch (action.type) {
        case POST_COO_VERIFY_FORM_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export const getRatificationVerification = (state = false, action) => {
    switch (action.type) {
        case GET_RATIFICATION_VERIFY:
            return true;
        case GET_RATIFICATION_VERIFY_RETURNED:
            return false;
        default:
            return state;
    }
};
export const ratificationVerificationReturned = (state = false, action) => {
    switch (action.type) {
        case GET_RATIFICATION_VERIFY_RETURNED:
            return action.data;
        default:
            return state;
    }
};

export const serviceLogIn = (state = false, action) => {
    switch (action.type) {
        case SERVICE_LOGIN:
            return true;
        case SERVICE_LOGIN_DONE:
            return false;
        default:
            return state;
    }
};
export const serviceLogInDone = (state = 0, action) => {
    switch (action.type) {
        case SERVICE_LOGIN_DONE:
            return action.status;
        default:
            return state;
    }
};

export const selectMenuData = (state = false, action) => {
    switch (action.type) {
        case FETCH_SELECT_MENU_DATA:
            return true;
        case FETCH_SELECT_MENU_DATA_DONE:
            return false;
        default:
            return state;
    }
};
export const selectMenuDataDone = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SELECT_MENU_DATA_DONE:
            return action.data;
        default:
            return state;
    }
};

export const calculateFees = (state = false, action) => {
    switch (action.type) {
        case CALCULATE_FEES:
            return true;
        case CALCULATE_FEES_DONE:
            return false;
        default:
            return state;
    }
};
export const calculateFeesDone = (state = {}, action) => {
    switch (action.type) {
        case CALCULATE_FEES_DONE:
            return action.data;
        default:
            return state;
    }
};

export const sendNewCOOForm = (state = false, action) => {
    switch (action.type) {
        case SAVE_NEW_COO_DATA:
            return true;
        case SAVE_NEW_COO_DATA_DONE:
            return false;
        default:
            return state;
    }
};
export const sendNewCOOFormDone = (state = {}, action) => {
    switch (action.type) {
        case SAVE_NEW_COO_DATA_DONE:
            return action.data;
        default:
            return state;
    }
};
export const uploadRakFile = (state = false, action) => {
    switch (action.type) {
        case UPLOAD_RAK_FILE:
            return true;
        case UPLOAD_RAK_FILE_DONE:
            return false;
        default:
            return state;
    }
};
export const uploadRakFileDone = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_RAK_FILE_DONE:
            return action.data;
        default:
            return state;
    }
};
export const getRakRequestList = (state = false, action) => {
    switch (action.type) {
        case GET_RAK_REQUESTS_LIST:
            return true;
        case GET_RAK_REQUESTS_LIST_DONE:
            return false;
        default:
            return state;
    }
};
export const rakRequestListDone = (state = {}, action) => {
    switch (action.type) {
        case GET_RAK_REQUESTS_LIST_DONE:
            return action.data;
        default:
            return state;
    }
};

export const getCooVerificationData = (state = false, action) => {
    switch (action.type) {
        case GET_COO_VERIFY_DATA:
            return true;
        case GET_COO_VERIFY_DATA_RETURNED:
            return false;
        default:
            return state;
    }
};
export const cooVerificationDataReturned = (state = [], action) => {
    switch (action.type) {
        case GET_COO_VERIFY_DATA_RETURNED:
            return action.data;
        default:
            return state;
    }
};
export const getCooAccreditedList = (state = false, action) => {
    switch (action.type) {
        case GET_COO_ACCREDITED_LIST:
            return true;
        case GET_COO_ACCREDITED_LIST_DONE:
            return false;
        default:
            return state;
    }
};
export const cooAccreditedListDone = (state = [], action) => {
    switch (action.type) {
        case GET_COO_ACCREDITED_LIST_DONE:
            return action.data;
        default:
            return state;
    }
};

export const sendCooAdditionalRequest = (state = false, action) => {
    switch (action.type) {
        case SEND_COO_ADDITIONAL_REQUEST:
            return true;
        case SEND_COO_ADDITIONAL_REQUEST_DONE:
            return false;
        default:
            return state;
    }
};
export const cooAdditionalRequestDone = (state = [], action) => {
    switch (action.type) {
        case SEND_COO_ADDITIONAL_REQUEST_DONE:
            return action.data;
        default:
            return state;
    }
};

export const sendCooEditRequest = (state = false, action) => {
    switch (action.type) {
        case SEND_COO_EDIT_REQUEST:
            return true;
        case SEND_COO_EDIT_REQUEST_DONE:
            return false;
        default:
            return state;
    }
};
export const cooEditRequestDone = (state = [], action) => {
    switch (action.type) {
        case SEND_COO_EDIT_REQUEST_DONE:
            return action.data;
        default:
            return state;
    }
};

export const getGoodsDetailsList = (state = false, action) => {
    switch (action.type) {
        case GET_GOODS_DETAILS_LIST:
            return true;
        case GET_GOODS_DETAILS_LIST_DONE:
            return false;
        default:
            return state;
    }
};
export const goodsDetailsList = (state = [], action) => {
    switch (action.type) {
        case GET_GOODS_DETAILS_LIST_DONE:
            return action.data;
        default:
            return state;
    }
};

export const sendCooGoodsEditRequest = (state = false, action) => {
    switch (action.type) {
        case SEND_COO_EDIT_GOODS:
            return true;
        case SEND_COO_EDIT_GOODS_DONE:
            return false;
        default:
            return state;
    }
};
export const cooGoodsEditRequestDone = (state = [], action) => {
    switch (action.type) {
        case SEND_COO_EDIT_GOODS_DONE:
            return action.data;
        default:
            return state;
    }
};

export const getRatificationType = (state = false, action) => {
    switch (action.type) {
        case GET_RATIFICATION_TYPE:
            return true;
        case GET_RATIFICATION_TYPE_DONE:
            return false;
        default:
            return state;
    }
};
export const ratificationType = (state = [], action) => {
    switch (action.type) {
        case GET_RATIFICATION_TYPE_DONE:
            return action.data;
        default:
            return state;
    }
};

export const getRatificationDocType = (state = false, action) => {
    switch (action.type) {
        case GET_RATIFICATION_DOC_TYPE:
            return true;
        case GET_RATIFICATION_DOC_TYPE_DONE:
            return false;
        default:
            return state;
    }
};
export const ratificationDocType = (state = [], action) => {
    switch (action.type) {
        case GET_RATIFICATION_DOC_TYPE_DONE:
            return action.data;
        default:
            return state;
    }
};

export const sendRatificationReq = (state = false, action) => {
    switch (action.type) {
        case SEND_RATIFICATION_REQUEST:
            return true;
        case SEND_RATIFICATION_REQUEST_DONE:
            return false;
        default:
            return state;
    }
};
export const ratificationRequest = (state = [], action) => {
    switch (action.type) {
        case SEND_RATIFICATION_REQUEST_DONE:
            return action.data;
        default:
            return state;
    }
};

export const getMembershipVerify = (state = false, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_VERIFY:
            return true;
        case GET_MEMBERSHIP_VERIFY_DONE:
            return false;
        default:
            return state;
    }
};
export const membershipVerify = (state = {}, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_VERIFY_DONE:
            return action.data;
        default:
            return state;
    }
};

export const getMembershipFees = (state = false, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_FEES:
            return true;
        case GET_MEMBERSHIP_FEES_DONE:
            return false;
        default:
            return state;
    }
};
export const membershipFees = (state = {}, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_FEES_DONE:
            return action.data;
        default:
            return state;
    }
};

export const spinnerToggle = (state = false, action) => {
    switch (action.type) {
        case IS_LOADING:
            return true;
        case LOADING_DONE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    spinnerToggle,
    postBusinessDirectoryForm,
    businessDirectoryFormReturned,
    getEServicesDetails,
    eServicesDetailsReturned,
    postCooVerificationForm,
    cooVerificationFormReturned,
    getRatificationVerification,
    ratificationVerificationReturned,
    serviceLogIn,
    serviceLogInDone,
    selectMenuData,
    selectMenuDataDone,
    calculateFees,
    calculateFeesDone,
    sendNewCOOForm,
    sendNewCOOFormDone,
    uploadRakFile,
    uploadRakFileDone,
    getRakRequestList,
    rakRequestListDone,
    getCooVerificationData,
    cooVerificationDataReturned,
    getCooAccreditedList,
    cooAccreditedListDone,
    sendCooAdditionalRequest,
    cooAdditionalRequestDone,
    sendCooEditRequest,
    cooEditRequestDone,
    getGoodsDetailsList,
    goodsDetailsList,
    sendCooGoodsEditRequest,
    cooGoodsEditRequestDone,
    getRatificationType,
    ratificationType,
    getRatificationDocType,
    ratificationDocType,
    sendRatificationReq,
    ratificationRequest,
    getMembershipVerify,
    membershipVerify,
    getMembershipFees,
    membershipFees,
});
