import { Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    createMuiTheme,
    jssPreset,
    StylesProvider,
    ThemeProvider,
} from "@material-ui/core/styles";
import axios from "axios";
import { create } from "jss";
import rtl from "jss-rtl";
import React, { lazy, memo, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { darkTheme, makeTheme } from "../styles/globalTheme/makeTheme";
import TabTitle from "./shared/TabTitle";
import ToTop from "./shared/ToTop";

const Spinner = lazy(() => import("../Spinner"));
const Home = lazy(() => import("./Home"));
const Header = lazy(() => import("../components/Header/Header"));
const SearchResults = lazy(() => import("../components/Header/SearchResults"));
const PrivacyPolicy = lazy(() =>
    import("../routes/privacyPolicy/PrivacyPolicy")
);

const Footer = lazy(() => import("../components/footer/Footer"));
//Featured Topics
const FeaturedTopicsMain = lazy(() =>
    import("./featuredTopics/FeaturedTopicsMain")
);
const FeaturedTopics = lazy(() => import("./featuredTopics/FeaturedTopics"));
const FeatredTopicDetails = lazy(() =>
    import("./featuredTopicDetails/FeaturedTopicsDetails")
);

//newSection
const NewSection = lazy(() => import("./newSection/Section"));

//Brief
const Brief = lazy(() => import("./brief/Brief"));
const DetailsPage = lazy(() => import("./shared/DetailsPage"));

//EPC
const GeneralPage = lazy(() => import("./EPC/generalPage"));
const AboutEPC = lazy(() => import("./EPC/aboutEPC/AboutEPC"));
const PresidentMessage = lazy(() =>
    import("./EPC/presidentMessage/PresidentMessage")
);
const Partners = lazy(() => import("./EPC/partners/Partners"));
const Sponsors = lazy(() => import("./EPC/sponsors/Sponsors"));
const Careers = lazy(() => import("./EPC/careers/Careers"));
const CareerDetails = lazy(() => import("./EPC/careers/CareerDetails"));
const CareerForm = lazy(() => import("./EPC/careers/CareerForm"));
const ContactUs = lazy(() => import("./EPC/contactUs/ContactUs"));

//events
const Events = lazy(() => import("./events/Events"));
const EventDetails = lazy(() => import("./events/eventDetails/EventDetails"));

//research units
const ResearchUints = lazy(() => import("./researchUnits/ResearchUints"));

//academy
const Academy = lazy(() => import("./academy/Academy"));
const AcademyDetails = lazy(() => import("./academy/AcademyDetails"));
const RegisterationForm = lazy(() => import("./academy/RegisterationForm"));

//publications
const Publications = lazy(() => import("./publications/Publications"));
const PublicationDetails = lazy(() =>
    import("./publications/PublicationDetails")
);
const PublicationsLists = lazy(() => import("./publications/Lists"));
// const RegisterationForm = lazy(() => import("./publications/RegisterationForm"));

//media
const News = lazy(() => import("./media/news/News"));
const PhotoGallery = lazy(() => import("./media/photoGallery/PhotoGallery"));
const PhotosDetails = lazy(() => import("./media/photoGallery/PhotosDetails"));
const VideoGallery = lazy(() => import("./media/videoGallery/VideoGallery"));
const VideoGalleryDetails = lazy(() =>
    import("./media/videoGallery/VideoGalleryDetails")
);

//mediaCenter
const MediaCenter = lazy(() => import("./mediaCenter/MediaCenter"));
//researchers
const Researchers = lazy(() => import("./researchers/Researchers"));
const ResearchersDetails = lazy(() =>
    import("./researchers/ResearchersDetails")
);
//expert's comment
const ExpertComment = lazy(() => import("./expertComment/ExpertComment"));
const ExpertCommentDetail = lazy(() =>
    import("./expertComment/ExpertCommentDetail")
);
//scenarios
const ScenariosPage = lazy(() => import("./scenarios/ScenariosPage"));
const ScenarioDetails = lazy(() => import("./scenarios/ScenarioDetails"));
//infographics
const Inforgraphics = lazy(() => import("./inforgraphics/Inforgraphics"));
const InforgraphicsDetail = lazy(() =>
    import("./inforgraphics/InforgraphicsDetail")
);

//Sections
const Sections = lazy(() => import("./section/Sections"));
const SectionsDetails = lazy(() => import("./section/SectionsDetails"));

const Redirection = lazy(() => import("../Redirection"));
const RSS = lazy(() => import("./EPC/RSS"));
function Routes() {
    const {
        basicTheme,
        basicTheme: { isRTL, isDark },
    } = useSelector((state) => state.theme_reducer);
    const [Pages, setPages] = useState([]);
    var [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const language = isRTL ? "ar" : "en";
            const options = {
                headers: {
                    language,
                },
                params: { type: "generalPages" },
            };
            let Pages = [];
            await axios
                .get(`/api/post`, { ...options })
                .then(async (response) => {
                    Pages = response.data.posts;
                    await setPages(Pages);
                });
        })();
    }, [isRTL]);

    useEffect(() => {
        const url = window.location.pathname;
        const urlParts = url.split("/").filter(Boolean);
        const currentLanguage = urlParts[0];
        if (currentLanguage == "ar")
            sessionStorage.setItem("SUBSCRIBE_FORM_AR", "true");
        if (currentLanguage == "en")
            sessionStorage.setItem("SUBSCRIBE_FORM_EN", "true");
    }, []);

    function isLoading(Isloading) {
        setLoading(Isloading);
    }
    function LOAD() {
        return loading;
    }

    const { url } = useRouteMatch();
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

    const theme = isDark ? darkTheme(basicTheme) : makeTheme(basicTheme);
    const materialTheme = createMuiTheme(theme);
    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={materialTheme}>
                <CssBaseline />

                <Paper
                    width="100%"
                    dir={isRTL ? "rtl" : "ltr"}
                    style={{
                        position: "relative",
                        background: theme.globals.colors.white,
                        boxShadow: "unset",
                    }}
                >
                    <ToTop />
                    <TabTitle />
                    <Suspense fallback={<h1></h1>}>
                        <Header />

                        <Switch>
                            <Route
                                exact
                                path={`${url}/home`}
                                render={() => <Home isLoading={isLoading} />}
                            />
                            <Route
                                exact
                                path={`${url}/search/results`}
                                render={() => <SearchResults />}
                            />
                            <Route
                                exact
                                path={`${url}/brief`}
                                render={(props) => (
                                    <Brief {...props} isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/details/:type/:alias`}
                                render={() => (
                                    <DetailsPage isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/media`}
                                render={() => (
                                    <MediaCenter isLoading={isLoading} />
                                )}
                            />
                            {Pages.map((link) => {
                                return (
                                    <Route
                                        exact
                                        path={`${url}/${link?.alias}`}
                                        render={() => (
                                            <GeneralPage
                                                alias={link?.alias}
                                                isLoading={isLoading}
                                            />
                                        )}
                                    />
                                );
                            })}
                            {/* <Route
                exact
                path={`${url}/page-epc`}
                render={() => <GeneralPage />}
              /> */}

                            <Route
                                exact
                                path={`${url}/about-us/about-epc`}
                                render={() => <AboutEPC />}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/president-message`}
                                render={() => <PresidentMessage />}
                            />
                            <Route
                                exact
                                path={`${url}/privacyPolicy`}
                                render={() => <PrivacyPolicy />}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/partners`}
                                render={() => (
                                    <Partners isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/sponsors`}
                                render={() => (
                                    <Sponsors isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/media/photos`}
                                render={() => (
                                    <PhotoGallery isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/contact-us`}
                                render={() => (
                                    <ContactUs isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/careers`}
                                render={() => <Careers isLoading={isLoading} />}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/careers/:alias`}
                                render={() => (
                                    <CareerDetails isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/careers/apply/:alias`}
                                render={() => (
                                    <CareerForm isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/event`}
                                render={(props) => (
                                    <Events {...props} isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/event/:alias`}
                                render={() => (
                                    <EventDetails isLoading={isLoading} />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/academy`}
                                render={() => <Academy isLoading={isLoading} />}
                            />

                            <Route
                                exact
                                path={`${url}/academy/:alias`}
                                render={() => (
                                    <AcademyDetails isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/academy/course-registeration/:alias`}
                                render={() => (
                                    <RegisterationForm isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/publications`}
                                render={(props) => (
                                    <Publications
                                        {...props}
                                        isLoading={isLoading}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/publications/:alias`}
                                render={() => (
                                    <PublicationDetails isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/publications/list/:alias`}
                                render={() => (
                                    <PublicationsLists isLoading={isLoading} />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/media/news`}
                                render={() => <News isLoading={isLoading} />}
                            />

                            <Route
                                exact
                                path={`${url}/media/photos/:alias`}
                                render={() => (
                                    <PhotosDetails isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/media/videos`}
                                render={() => (
                                    <VideoGallery isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/media/videos/:alias`}
                                render={() => (
                                    <VideoGalleryDetails
                                        isLoading={isLoading}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/about-us/researchers`}
                                render={() => (
                                    <Researchers isLoading={isLoading} />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/about-us/researchers/:id`}
                                render={() => (
                                    <ResearchersDetails isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/expertComment`}
                                render={() => (
                                    <ExpertComment isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/expertComment/:id/:alias`}
                                render={() => (
                                    <ExpertCommentDetail
                                        isLoading={isLoading}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/infographics`}
                                render={() => (
                                    <Inforgraphics isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/epc/infographics/:alias`}
                                render={() => (
                                    <InforgraphicsDetail
                                        isLoading={isLoading}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/sections`}
                                render={(props) => (
                                    <Sections
                                        {...props}
                                        isLoading={isLoading}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/sections/SectionsDetails`}
                                render={() => (
                                    <SectionsDetails isLoading={isLoading} />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/scenarios`}
                                render={(props) => (
                                    <ScenariosPage
                                        {...props}
                                        isLoading={isLoading}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/ScenariosPage/scenario/:alias`}
                                render={() => (
                                    <ScenarioDetails isLoading={isLoading} />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/:alias`}
                                component={() => (
                                    <NewSection isLoading={isLoading} />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/featured/:alias`}
                                component={() => (
                                    <FeaturedTopics isLoading={isLoading} />
                                )}
                            />

                            <Route
                                exact
                                path={`${url}/features/all`}
                                component={(props) => (
                                    <FeaturedTopicsMain
                                        {...props}
                                        isLoading={isLoading}
                                    />
                                )}
                            />

                            {/* <Route
                exact
                path={`${url}/topic/:alias`}
                component={() => <FeatredTopicDetails isLoading={isLoading} />}
              /> */}
                            {/* <Route
                exact
                path={`${url}/section/:alias`}
                component={() => <Random isLoading={isLoading} />}
              /> */}
                            <Route
                                exact
                                path={`${url}/units/:category`}
                                render={() => (
                                    <ResearchUints isLoading={isLoading} />
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/rss/rss-feed`}
                                render={() => <RSS />}
                            />
                        </Switch>
                        {LOAD() ? "" : <Footer />}
                    </Suspense>
                </Paper>
            </ThemeProvider>
        </StylesProvider>
    );
}

export default memo(Routes);
