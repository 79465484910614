import { put, takeLatest, call } from "redux-saga/effects";
import { submitRequest } from "../../network/request";
import actions from "../../actions";

const { SUBMIT_REQUEST, submitRequestReturned } = actions;

function* performSubmitRequest(action) {
    try {
        let data = action.data;

        const result = yield call(submitRequest, data);

        if (result) yield put(submitRequestReturned({ data: result.data }));
        else yield put(submitRequestReturned({ data: [] }));
    } catch (error) {
        console.error(error);
    }
}
export function* watchSubmitRequest() {
    yield takeLatest(SUBMIT_REQUEST, performSubmitRequest);
}
